<template>
  <div class="container">
    <!-- 占位div -->
    <div :style="{ height: $store.state.changeHeight + 'px' }"></div>
    <div class="main flex-space-between">
      <div style="flex:1;">
        <div class="continue_font" @click="$router.push('/')">
          <i class="el-icon-arrow-left" />
          <span style="cursor: pointer;">Continue Shopping</span>
        </div>

        <h1 class="bigTitle" style="line-height: 1.25;padding-left: 10px;">Shopping Cart</h1>
        <div v-if="ticketsCopy1.length+ expressTicket.length + braceletList.length !==0">
          <!-- Ticket -->
          <div v-if="ticketsCopy1.length > 0">
            <div class="base">TICKETS</div>
            <div class="ticket-list">
              <div
                v-for="(item, index) in ticketsCopy1"
                :key="index"
                class="ticket-item font20 flex-space-between"
              >
                <div class="ticket_item_left">
                  <p class="ticketName">{{ getTicketTypeName(item.ticketKind) }}</p>
                  <div class="font14">
                    <p>
                      <span v-if="item['1'] && item['1'].num > 0 && item.ticketKind !== 12">
                        {{ item["1"].num || 0 }}
                        {{ item["1"].num >= 2 ? "Adults" : "Adult" }}
                      </span>
                      <span v-else-if="item['1'] && item['1'].num > 0 && item.ticketKind === 12">
                        {{ item["1"].num || 0 }}
                        {{ item["1"].num >= 2 ? "All Ages" : "All Ages" }}
                      </span>
                      <span
                        v-if="
                          item['1'] &&
                            item['1'].num > 0 &&
                            item['2'] &&
                            item['2'].num > 0 
                        "
                      >+</span>
                      <span v-if="item['2'] && item['2'].num > 0">
                        {{ item["2"].num || 0 }}
                        {{ item["2"].num >= 2 ? "Children" : "Child" }}
                      </span>
                      <span v-if="item['3']">
                        <span
                          v-if="
                        (item['1'] &&item['1'].num > 0 && item['3'] &&item['3'].num > 0) ||
                         (item['2'] &&item['2'].num > 0 && item['3'] &&item['3'].num > 0)
                        "
                        >+</span>
                        <span v-if="item['3'] && item['3'].num > 0">
                          {{ item["3"].num || 0 }}
                          {{ item["3"].num >= 2 ? "Seniors" : "Senior" }}
                        </span>
                        <span v-if="item['4'] && item['4'].num > 0">
                          {{ item["4"].num || 0 }}
                          All Ages
                        </span>
                      </span>
                    </p>
                    <p>{{ changeDate(item.travelDate) }}</p>
                    <!-- <p class="ticketDate" style="font-weight: 600;">{{ conversionPeriod(item.travelDate) }}</p> -->
                  </div>
                  <span @click="delTicket(index, 1, item)" class="remove_font">REMOVE</span>
                </div>
                <!-- 票计数器start -->
                <div class="ticket_item_right" v-show="item.ticketKind!==7">
                  <div
                    v-if="item['1'] && item['1'].num >= 0"
                    class="ticket_item_right_inp-number-box"
                  >
                    <p class="mr15 ticketPrice">
                      <span v-if="item.ticketKind!==12" class="font18 small-weight">Adult</span>
                      <span v-else class="font18 small-weight">All Ages</span>
                      <span
                        class="font12"
                        style="color: grey"
                      >(¥{{ item["1"].price | abs }} per ticket)</span>
                    </p>
                    <div class="input-number">
                      <span
                        @click="ticketNumLess(index, 'adult')"
                        class="blueFont font24 less"
                        style
                      >-</span>
                      <span class="blueFont num">{{ item["1"].num }}</span>
                      <span @click="ticketNumAdd(index, 'adult')" class="blueFont plus">+</span>
                    </div>
                    <p class="font20 subTotal">¥{{ item["1"].num * item["1"].price || 0 | abs }}</p>
                  </div>
                  <div
                    v-if="item['2'] && item['2'].num >= 0 && item.ticketKind!==9 && item.ticketKind!==12"
                    class="ticket_item_right_inp-number-box"
                  >
                    <p class="mr15 ticketPrice">
                      <span class="font18 small-weight">Child</span>
                      <span
                        class="font12"
                        style="color: grey"
                      >(¥{{ item["2"].price | abs }} per ticket)</span>
                    </p>
                    <div class="input-number">
                      <span @click="ticketNumLess(index, 'child')" class="blueFont font24 less">-</span>
                      <span class="blueFont num">{{ item["2"].num }}</span>
                      <span @click="ticketNumAdd(index, 'child')" class="blueFont plus">+</span>
                    </div>
                    <span class="font20 subTotal">¥{{ item["2"].num * item["2"].price || 0 | abs }}</span>
                  </div>
                  <div
                    v-if="item['3'] && item['3'].num >= 0 && item.ticketKind===1"
                    class="ticket_item_right_inp-number-box"
                  >
                    <p class="mr15 ticketPrice">
                      <span class="font18 small-weight">Senior</span>
                      <span
                        class="font12"
                        style="color: grey"
                      >(¥{{ item["3"].price | abs }} per ticket)</span>
                    </p>
                    <div class="input-number">
                      <span @click="ticketNumLess(index, 'senior')" class="blueFont font24 less">-</span>
                      <span class="blueFont num">{{ item["3"].num }}</span>
                      <span @click="ticketNumAdd(index, 'senior')" class="blueFont plus">+</span>
                    </div>
                    <span class="font20 subTotal">¥{{ item["3"].num * item["3"].price || 0 | abs }}</span>
                  </div>
                </div>
                <div
                  class="ticket_item_right"
                  v-show="item['4'] && item['4'].num >= 0 && item.ticketKind===7"
                >
                  <div
                    v-if="item['4'] && item['4'].num >= 0 && item.ticketKind===7"
                    class="ticket_item_right_inp-number-box"
                  >
                    <p class="mr15 ticketPrice">
                      <span class="font18 small-weight">All Ages</span>
                      <span
                        class="font12"
                        style="color: grey"
                      >(¥{{ item["4"].price | abs }} per ticket)</span>
                    </p>
                    <div class="input-number">
                      <span @click="ticketNumLess(index, 'allYear')" class="blueFont font24 less">-</span>
                      <span class="blueFont num">{{ item["4"].num }}</span>
                      <span @click="ticketNumAdd(index, 'allYear')" class="blueFont plus">+</span>
                    </div>
                    <span class="font20 subTotal">¥{{ item["4"].num * item["4"].price || 0 | abs }}</span>
                  </div>
                </div>
                <!-- 票计数器end -->
              </div>
            </div>
          </div>
          <!-- Express Pass -->
          <div v-if="expressTicket.length > 0">
            <div class="base">EXPRESS PASSES</div>
            <div class="ticket-list mb10">
              <div
                v-for="(item, index) in expressTicket"
                :key="index"
                class="ticket-item font20 flex-space-between"
              >
                <div class="ticket_item_left">
                  <p class="ticketName">{{ item.officialName }}</p>
                  <div class="font14">
                    <p>{{ item.num }} All Ages</p>
                    <p class="ticketDate">{{ changeDate(item.travelDate) }}</p>
                    <!-- 场次 -->
                    <!-- <p>{{ item.batName }}</p> -->
                    <p v-for="(item, index) of splitBatName(item.batName)" :key="index">{{ item }}</p>
                  </div>
                  <p @click="delExpressTicket(index, item)" class="remove_font">REMOVE</p>
                </div>
                <!-- 票价格及计数器 -->
                <div class="ticket_item_right">
                  <div class="ticket_item_right_inp-number-box">
                    <p class="mr15 ticketPrice">
                      <span class="font18 small-weight">All Ages</span>
                      <span
                        class="font12"
                        style="color: grey"
                      >(¥{{ item.price || 0 | abs }} per ticket)</span>
                    </p>
                    <div class="input-number">
                      <span @click="ticketNumLess(index, 'express')" class="blueFont font24 less">-</span>
                      <span class="blueFont num">{{ item.num }}</span>
                      <span @click="ticketNumAdd(index, 'express')" class="blueFont plus">+</span>
                    </div>
                    <p class="boldFont subTotal">¥{{ item.num * item.price || 0 | abs }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Extras -->
          <div v-if="braceletList && braceletList.length > 0">
            <div class="base">EXTRAS</div>
            <div class="ticket-list mb10">
              <div
                v-for="(item, index) in braceletList"
                :key="index"
                class="ticket-item font20 flex-space-between"
              >
                <div class="ticket_item_left">
                  <p class="ticketName">{{ item.officialName }}</p>
                  <div class="font14">
                    <p>{{ item.num }} All Ages</p>
                    <p class="ticketDate">{{changeDate(item.travelDate) }}</p>
                    <!-- 场次 -->
                    <p  v-for="(item, index) of splitBatName(item.batName)" :key="index">{{ item }}</p>
                  </div>
                  <p @click="delTicket(index, 3, item)" class="remove_font">REMOVE</p>
                </div>
                <!-- 票价格及计数器 -->
                <div class="ticket_item_right">
                  <div class="ticket_item_right_inp-number-box">
                    <p class="mr15 ticketPrice">
                      <span class="font18 small-weight">All Ages</span>
                      <span
                        class="font12"
                        style="color: grey"
                      >(¥{{ item.price || 0 | abs }} per ticket)</span>
                    </p>
                    <div class="input-number">
                      <span @click="ticketNumLess(index, 'option')" class="blueFont font24 less">-</span>
                      <span class="blueFont num">{{ item.num }}</span>
                      <span @click="ticketNumAdd(index, 'option')" class="blueFont plus">+</span>
                    </div>
                    <p class="boldFont subTotal">¥{{ item.num * item.price || 0 | abs }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <shopCartNotice v-if="expressTicket.length > 0 && ticketsCopy1.length === 0 || braceletList.length > 0 && ticketsCopy1.length === 0 " />
          <div class="deliveryBox">
            <h2>Delivery Method</h2>
            <div class="content">
              Please select the delivery method for the applicable items in your
              order. Must have valid ID ready to show at front gate.
            </div>
            <div class="delivery-method-item">
              <div class="checkBox">
                <div :class="{ 'blue-dot': checkBox }" />
              </div>
              <div>
                <span class="checkBox_label">Email Address</span>
                <div class="font12">Receive the electronic tickets</div>
              </div>
            </div>
          </div>
        </div>
        <p v-else class="no_product_tip">There are currently no items in your cart.</p>
        <!-- 购物车费用详情，小尺寸才显示start -->
        <div class="order_box">
          <div class="feed-detail">
            <div v-if="tickets.length > 0">
              <el-row class="order"></el-row>
              <div class="total_box">
                <div class="txt_total">Total:</div>
                <div class="totalPrice">¥{{ total || 0 | abs }}</div>
              </div>
            </div>
          </div>
          <el-button
            @click="checkOut()"
            :disabled="ticketsCopy1.length+ expressTicket.length + braceletList.length ===0"
            class="checkout-btn"
            :class="{ 'checkout-btn_disable': ticketsCopy1.length+ expressTicket.length + braceletList.length ===0 }"
            type="primary"
          >CHECKOUT</el-button>
        </div>
        <!-- 购物车费用详情，小尺寸才显示end -->
      </div>
      <!-- 侧边栏 -->
      <div id="aside" class="aside hidden font14">
        <div id="asideContent">
          <el-card class="box-card">
            <div slot="header" class="clearfix font_weight_700 font16">Order Summary</div>
            <div class="card-foot">
              <div class="flex-space-between">
                <span class="font_weight_700">Total:</span>
                <div>
                  <p class="font_weight_700" style="text-align: right;">¥{{ total || 0 | abs }}</p>
                  <p class="font12">(Tax included)</p>
                </div>
              </div>
            </div>
          </el-card>
          <el-button
            @click="checkOut()"
            :disabled="ticketsCopy1.length+ expressTicket.length + braceletList.length ===0"
            class="checkout-btn"
            :class="{ 'checkout-btn_disable': ticketsCopy1.length+ expressTicket.length + braceletList.length ===0 }"
            type="primary"
          >CHECKOUT</el-button>
        </div>
      </div>
    </div>
    <!-- 购物车删除确认框 -->
    <delTicketConfirmDialog
      v-if="confirmDialogVisible"
      ref="confirmDialog"
      @confirm="delTicketConfirm"
    />
  </div>
</template>

<script>
import { formatDate, getDateStr } from "@/utils/util";
import { mixin1, mixin2 } from "@/mixin/index";
import { getCalendarProductList } from "@/api/index";
import delTicketConfirmDialog from "../../components/delTicketConfirmDialog.vue";
import shopCartNotice from "@/components/shopCartNotice.vue";
import dayjs from 'dayjs'
import { ifBeforeToday } from "@/mixin/index.js";
export default {
  mixins: [mixin1, mixin2],
  components: { delTicketConfirmDialog, shopCartNotice },
  data() {
    return {
      checkBox: true,
      orderInfo: {},
      tickets: [],
      expressTicket: [], // ticketsCopy里的快速票
      expressTicket1: [],
      ticketsCopy: [],
      ticketsCopy1: [],
      delTicketItem: {}, // 被删除的票
      confirmDialogVisible: false,
      braceletList: [], // 手环列表
      ticketKind: 0, // 被删除的票类型
      delTicketIndex: "", // 删除的票的index
      delTicketId: "", // 被删除的快速券的id
      total: 0,
      initialstockNum: 0,
      startAddCartDateTimer:null,
    };
  },
  beforeDestroy() {
    if(this.startAddCartDateTimer) {
      clearInterval(this.startAddCartDateTimer); //关闭
      this.startAddCartDateTimer = null;
    }
  },
  mounted() {
  
    const asideContent = document.querySelector("#asideContent");
    asideContent.style.top = 40 + "px";
    window.addEventListener("scroll", this.handleScroll);
    // 判断购物车是否今天之前日期
    ifBeforeToday()

    this.loadData();
    this.dataHandle();
    // 判断购物车内容是否加入了三个小时
    this.ifEndAddCartDateFn()
    console.log('购物车',this.ticketsCopy);
    this.ticketsCopy.forEach(item => {
      if (
        item.ticketKind === 1 ||
        item.ticketKind === 2 ||
        item.ticketKind === 5 ||
        item.ticketKind === 7 ||
        item.ticketKind === 9 ||
        item.ticketKind === 12
      ) {
        this.ticketsCopy1.push(item);
      }
    });
    JSON.parse(localStorage.getItem("orderInfo")).orderRequestProductList.map(
      (item, index) => {
        if (item.ticketKind === 3) {
          this.expressTicket.push(item);
        } else if (item.ticketKind === 8) {
          this.braceletList.push(item);
        }
      }
    );
    this.braceletList.forEach(item => {
      this.initialstockNum += item.num;
    });
    console.log(this.braceletList);
    this.getInventory();
    this.getSubtotal();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ifEndAddCartDateFn(){
      // 设置一个每1000毫秒执行一次的定时器
      // let startAddCartDate = localStorage.getItem('startAddCartDate');
      let endAddCartDate = localStorage.getItem('endAddCartDate');
      // console.log('获取购物车三小时后的时间',endAddCartDate);
      if(endAddCartDate && endAddCartDate !=  null && this.tickets.length != 0){
        console.log('开启定时器');
        this.startAddCartDateTimer = setInterval(() => {
          console.log(localStorage.getItem('endAddCartDate'),!localStorage.getItem('endAddCartDate') || localStorage.getItem('endAddCartDate') == null);
        if(!localStorage.getItem('endAddCartDate') || localStorage.getItem('endAddCartDate') == null){
          // console.log('你关不关的');
          clearInterval(this.startAddCartDateTimer); //关闭
          this.startAddCartDateTimer = null;
        }
        console.log('定时器-当前',dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')); 
        console.log('定时器-三小时后',dayjs(+endAddCartDate).format('YYYY-MM-DD HH:mm:ss'));
          if(+new Date() > +endAddCartDate){
            // 三小时已到，清除购物车
            console.log('三小时已到，清除购物车');
            localStorage.setItem('orderInfo','{"orderRequestProductList":[],"currency": 5}')
            localStorage.setItem('oneDayTicketAvailableDate','[]')
            localStorage.setItem('expressTicketAvailableDate','[]')
            localStorage.setItem('twoDayAvailableDate','[]')
            clearInterval(this.startAddCartDateTimer); //关闭
            this.startAddCartDateTimer = null;
            localStorage.removeItem('endAddCartDate');
            location.reload();
          }
        }, 1000);
      }
    },
    // 获取本地数据
    loadData() {
      if (JSON.parse(localStorage.getItem("orderInfo")) !== null) {
        this.orderInfo = JSON.parse(localStorage.getItem("orderInfo"));
        this.tickets = this.orderInfo.orderRequestProductList;
      }
    },
    // 删除非快速券票
    delTicket(index, type, item) {
      this.ticketKind = item.ticketKind;
      this.delTicketItem = item;
      // 如果删除1.5/2日时如果还有其他票则提示
      if ([2, 5, 7].includes(this.ticketKind)) {
        this.confirmDialogVisible = true;
        this.$nextTick(() => {
          this.$refs.confirmDialog.init(item);
        });
      } else {
        if (type == 1) {
          this.ticketsCopy1.splice(index, 1);
        } else if (type === 2) {
          this.expressTicket.splice(index, 1);
        } else if (type === 3) {
          this.braceletList.splice(index, 1);
        }
        this.tickets = [];
        if (this.ticketsCopy1.length > 0) {
          this.ticketsCopy1.map(item => {
            this.tickets.push(...this.splitObj(item));
          });
        }
        this.tickets.push(...this.expressTicket);
        this.tickets.push(...this.braceletList);
        this.orderInfo.orderRequestProductList = this.tickets;
        localStorage.setItem("orderInfo", JSON.stringify(this.orderInfo)); // 更新本地数据
      }
      this.getSubtotal();
      this.$store.commit("SET_SHOPCART"); // 更新vuex的数据
      this.$store.commit("SET_TICKET_DATE"); // 更新票的可选时间
    },
    // 删除快速券
    delExpressTicket(index, item) {
      // 相符的次数 票id
      let Correspondingnumber = 0;
      // 相符的次数 票 日期
      let CorrespondingDatenumber = 0;
      // 相对应的手环名字
      let Correspondingname = "";
      // 相对应的手环日期
      let CorrespondingDate = "";
      //  记录删除指定快速券的日期
      this.delTicketDate = item.travelDate;
      this.ticketKind = item.ticketKind;
      // 如果购物车有手环，判断是否跟快速券有关
      if (this.braceletList.length > 0) {
        // const relatedBraceletProduct = this.braceletList.some(ticket => {
        //   return (
        //     ticket.ticketKind === 6 &&
        //     item.travelDate === ticket.travelDate &&
        //     ticket.expressTicketIds.includes(item.id.toString())
        //   );
        // });
          this.tickets = [];
          this.expressTicket.splice(index, 1);
          if (this.ticketsCopy1.length > 0) {
            this.ticketsCopy1.map(item => {
              this.tickets.push(...this.splitObj(item));
            });
          }
          this.tickets.push(...this.expressTicket);
          this.tickets.push(...this.braceletList);
          this.orderInfo.orderRequestProductList = [];
          this.orderInfo.orderRequestProductList = this.tickets;
          localStorage.setItem("orderInfo", JSON.stringify(this.orderInfo)); // 更新本地数据
          this.$store.commit("SET_SHOPCART"); // 更新vuex的数据
          this.$store.commit("SET_TICKET_DATE"); // 更新票的可选时间
          // this.handleTicket();
          // this.$router.go(0)
          this.getSubtotal();
        
      } else {
        // // 否则直接删除快速
        this.tickets = [];
        // 不懂
        if (this.ticketsCopy1.length > 0) {
          this.ticketsCopy1.map(item => {
            this.tickets.push(...this.splitObj(item));
          });
        }
        this.expressTicket.splice(index, 1);
        if (this.expressTicket.length > 0) {
          this.tickets.push(...this.expressTicket);
        }
        if (this.braceletList.length > 0) {
          this.tickets.push(...this.braceletList);
        }
        this.orderInfo.orderRequestProductList = [];
        this.orderInfo.orderRequestProductList = this.tickets;
        localStorage.setItem("orderInfo", JSON.stringify(this.orderInfo)); // 更新本地数据
        this.$store.commit("SET_SHOPCART"); // 更新vuex的数据
        this.$store.commit("SET_TICKET_DATE"); // 更新票的可选时间
        // this.handleTicket();
        // this.$router.go(0)
        this.getSubtotal();
      }
    },
    // 确认删除事件
    delTicketConfirm() {
      this.confirmDialog = false;
      // 删除1.5/2日时清空购物车
      if ([2, 5, 7].includes(this.ticketKind)) {
        this.tickets = [];
        this.ticketsCopy1 = [];
        this.braceletList = [];
        this.expressTicket = [];
        this.orderInfo.orderRequestProductList = this.tickets;
        localStorage.setItem("orderInfo", JSON.stringify(this.orderInfo)); // 更新本地数据
        this.$store.commit("SET_SHOPCART"); // 更新vuex的数据
        this.$store.commit("SET_TICKET_DATE"); // 更新票的可选时间
        this.getSubtotal();
        // this.$router.go(0)
      } else if (this.ticketKind === 3) {
        // 如果删除的是快速券判断是否删除手环

        const expressIds = [];
        // 记录所有快速券的id
        this.expressTicket.map(item => {
          expressIds.push(item.id);
        });
        this.confirmDialogVisible = false;
        this.expressTicket.splice(this.delTicketIndex, 1); // 删除快速券
        let delAfterArr = []; //删除后合并的数组
        let oneTwoArr = []; //1日,1.5日,2日数组
        // 获取一日券,二日券,1.5日券
        if (JSON.parse(localStorage.getItem("orderInfo")) !== null) {
          JSON.parse(
            localStorage.getItem("orderInfo")
          ).orderRequestProductList.forEach(item => {
            if (
              item.ticketKind === 1 ||
              item.ticketKind === 2 ||
              item.ticketKind === 5
            ) {
              oneTwoArr.push(item);
            }
          });
        }
      } else {
        // 删除有关手环产品
        if (this.braceletList.length > 0) {
          // 循环手环列表找到指定的快速券id和删除日期,并且删除对应的快速券和手环
          this.braceletList.forEach((item, index) => {
            if (item.travelDate === this.delTicketDate) {
              this.braceletList.splice(index, 1);
            }
          });
          localStorage.setItem("orderInfo", JSON.stringify(this.orderInfo)); // 更新本地数据
          this.$store.commit("SET_SHOPCART"); // 更新vuex的数据
          this.$store.commit("SET_TICKET_DATE"); // 更新票的可选时间
          // this.$router.go(0)
          this.getSubtotal();
        } else {
          this.tickets = [];
          this.tickets.push(...this.ticketsCopy);
          this.tickets.push(...this.expressTicket);
          this.tickets.push(...this.braceletList);
          if (this.ticketsCopy.length > 0) {
            this.ticketsCopy.map(item => {
              delete item.adultStore;
              delete item.childStore;
              this.tickets.push(...this.splitObj(item));
            });
          }
          this.orderInfo.orderRequestProductList = [];
          this.orderInfo.orderRequestProductList = this.tickets;
          localStorage.setItem("orderInfo", JSON.stringify(this.orderInfo)); // 更新本地数据
          this.$store.commit("SET_SHOPCART"); // 更新vuex的数据
          this.$store.commit("SET_TICKET_DATE"); // 更新票的可选时间
          // this.$router.go(0)
          this.getSubtotal();
        }
      }
    },
    // 计算总价
    getSubtotal() {
      let total = 0;
      if (this.ticketsCopy1.length > 0) {
        this.ticketsCopy1.forEach(item => {
          if (item["1"] && item["2"] && item["3"]) {
            total +=
              item["1"].num * item["1"].price +
              item["2"].num * item["2"].price +
              item["3"].num * item["3"].price;
          } else if (item["1"] && item["2"]) {
            total +=
              item["1"].num * item["1"].price + item["2"].num * item["2"].price;
          } else if (item["1"] && item["2"] && item["3"] && item["4"]) {
            total +=
              item["1"].num * item["1"].price +
              item["2"].num * item["2"].price +
              item["3"].num * item["3"].price +
              item["4"].num * item["4"].price;
          }
          if (item["4"]) {
            total += item["4"].num * item["4"].price;
          }
        });
      }

      if (this.expressTicket.length > 0) {
        this.expressTicket.map(item => {
          total += item.num * item.price;
        });
      }
      if (this.braceletList.length > 0) {
        this.braceletList.map(item => {
          total += item.num * item.price;
        });
      }
      console.log(total);
      this.total = total;
    },
    checkOut() {
      // google统计-分销商访问商品事件埋点
      this.$gtag.event("checkout", {
        event_category: "checkout",
        event_label: "checkout",
        value: 1000
      });
      // 如果没有选产品则不允许下一步
      if (
        this.ticketsCopy.length +
          this.expressTicket.length +
          this.braceletList.length ==
        0
      ) {
        return this.$message({
          message: "You have not selected a product",
          type: "warning",
          duration: 2000
        });
      }
      this.tickets = [];
      // 不懂
      if (this.ticketsCopy1.length > 0) {
        this.ticketsCopy1.map(item => {
          console.log(item);
          delete item.adultStore;
          delete item.childStore;
          this.tickets.push(...this.splitObj(item));
        });
      }
      if (this.expressTicket.length > 0) {
        this.expressTicket.map(item => {
          delete item.expressTicketStore;
        });
      }
      this.tickets.push(...this.expressTicket);
      this.tickets.push(...this.braceletList);

      this.orderInfo.orderRequestProductList = this.tickets;
      localStorage.setItem("orderInfo", JSON.stringify(this.orderInfo)); // 更新本地数据

      this.$router.push({ path: "/checkOut" });
    },
    // 获取票库存
    getInventory() {
      if (this.ticketsCopy.length > 0) {
        this.ticketsCopy.map(item => {
          // 获取成人票库存
          const param1 = {
            startTime: item.travelDate,
            endTime: getDateStr(item.travelDate, 1),
            ticketKind: item.ticketKind,
            ticketType: 1
          };
          getCalendarProductList(param1).then(res => {
            const result = res.data.data;
            if (result && result.length > 0) {
              result.map(item2 => {
                if (item2.travelTime.substr(0, 10) === item.travelDate) {
                  item.adultStore = item2.num;
                }
              });
            }
          });
          //获取儿童票库存
          const param2 = {
            startTime: item.travelDate,
            endTime: getDateStr(item.travelDate, 1),
            ticketKind: item.ticketKind,
            ticketType: 2
          };
          getCalendarProductList(param2).then(res => {
            const result = res.data.data;
            if (result && result.length > 0) {
              result.map(item2 => {
                if (item2.travelTime.substr(0, 10) === item.travelDate) {
                  item.childStore = item2.num;
                }
              });
            }
          });
          //获取老人票库存
          const param5 = {
            startTime: item.travelDate,
            endTime: getDateStr(item.travelDate, 1),
            ticketKind: item.ticketKind,
            ticketType: 3
          };
          getCalendarProductList(param5).then(res => {
            const result = res.data.data;
            if (result && result.length > 0) {
              result.map(item2 => {
                if (item2.travelTime.substr(0, 10) === item.travelDate) {
                  item.seniorStore = item2.num;
                }
              });
            }
          });
          //获取全年票库存
          const param6 = {
            startTime: item.travelDate,
            endTime: getDateStr(item.travelDate, 1),
            ticketKind: item.ticketKind
            // ticketType: 3
          };
          getCalendarProductList(param6).then(res => {
            const result = res.data.data;
            console.log(result);
            if (result && result.length > 0) {
              result.map(item2 => {
                if (item2.travelTime.substr(0, 10) === item.travelDate) {
                  item.allYearStore = item2.num;
                }
              });
            }
          });
        });
      }
      if (this.expressTicket.length > 0) {
        this.expressTicket.map(item => {
          const param3 = {
            startTime: item.travelDate,
            endTime: getDateStr(item.travelDate, 1),
            ticketKind: item.ticketKind,
            productId: item.productId
          };
          getCalendarProductList(param3).then(res => {
            const result = res.data.data;
            if (result && result.length > 0) {
              result.map(item3 => {
                if (item3.travelTime.substr(0, 10) === item.travelDate) {
                  item.expressTicketStore = item3.num; // 取库存num
                }
              });
            }
          });
        });
      }
      if (this.braceletList.length > 0) {
        this.braceletList.map(item => {
          const param4 = {
            startTime: item.travelDate,
            endTime: getDateStr(item.travelDate, 1),
            ticketKind: item.ticketKind,
            productId: item.productId
          };
          getCalendarProductList(param4).then(res => {
            const result = res.data.data;
            if (result && result.length > 0) {
              result.map(item4 => {
                if (item4.travelTime.substr(0, 10) === item.travelDate) {
                  item.extrasTicketStore = item4.num; // 取库存num
                }
              });
            }
          });
        });
      }
    },
    getTicketTypeName(value) {
      const options = {
        1: "1 Day Studio Pass",
        2: "2 Day Studio Pass",
        5: "1.5 Day Studio Pass",
        7: "NO LIMIT! Countdown 2025 PARTY PASS",
        9: "1 Day Studio Pass + Have Fun in Kansai",
        12: "1 Day Studio Pass + Expo 2025 Osaka Admission ticket",
      };
      return options[value];
    },
    // 设置有限期范围
    conversionPeriod(travelDate) {
      var startTime = travelDate;
      var endTime = "";
      var month = parseInt(startTime.substr(5, 2));
      var year = parseInt(startTime.substr(0, 4));
      var day = startTime.substr(8);
      if (parseInt(month + 3) > 12) {
        endTime = `${year + 1}-${month - 9}-${day}`;
      } else {
        endTime = `${year}-${month + 3}-${day}`;
      }
      startTime = formatDate(startTime);
      endTime = formatDate(endTime);
      return `Valid any same price day from ${startTime} ~ ${endTime}`;
    },
    // 票计数器减
    ticketNumLess(index, type) {
      // 保证至少1日/1.5日/2日票的数量为1
      if (type === "adult" || type === "child" || type === "senior") {
        if (
          this.ticketsCopy[index]["1"].num +
            this.ticketsCopy[index]["2"].num +
            this.ticketsCopy[index]["3"].num <=
          1
        ) {
          return;
        }
      }
      if (type === "adult") {
        if (this.ticketsCopy[index]["1"].num > 0) {
          --this.ticketsCopy[index]["1"].num;
        } else {
          this.ticketsCopy[index]["1"].num = 0;
        }
      } else if (type === "child") {
        if (this.ticketsCopy[index]["2"].num > 0) {
          --this.ticketsCopy[index]["2"].num;
        } else {
          this.ticketsCopy[index]["2"].num = 0;
        }
      } else if (type === "senior") {
        if (this.ticketsCopy[index]["3"].num > 0) {
          --this.ticketsCopy[index]["3"].num;
        } else {
          this.ticketsCopy[index]["3"].num = 0;
        }
      } else if (type === "allYear") {
        if (this.ticketsCopy[index]["4"].num > 1) {
          --this.ticketsCopy[index]["4"].num;
        } else {
          this.ticketsCopy[index]["4"].num = 1;
        }
      } else if (type === "express") {
        if (this.expressTicket[index].num > 1) {
          --this.expressTicket[index].num;
        }
      } else if (type === "option") {
        if (this.braceletList[index].num > 1) {
          --this.braceletList[index].num;
        }
      }

      this.getSubtotal();
    },
    splitBatName(str) {
      if(str===undefined) return
      if(str.length===0) return
      if (str !== undefined) {
        let strArr = str.split(")");
        for (let i = 0; i < strArr.length; i++) {
          strArr[i] += ")";
        }
        return strArr.splice(0, strArr.length - 1);
      }else{
        console.log('没有')
      }
    },
    // 票计数器加
    ticketNumAdd(index, type) {
      console.log(this.ticketsCopy);
      if (type === "adult") {
        if (this.ticketsCopy[index]["1"].num < 4) {
          if (
            this.ticketsCopy[index]["1"].num <
            this.ticketsCopy[index]["adultStore"]
          ) {
            this.ticketsCopy[index]["1"].num++;
          } else {
            this.$warning("Inventory shortage");
          }
        } else {
          this.$warning("Cannot exceed 4");
        }
      } else if (type === "child") {
        if (this.ticketsCopy[index]["2"].num < 4) {
          if (
            this.ticketsCopy[index]["2"].num <
            this.ticketsCopy[index]["childStore"]
          ) {
            this.ticketsCopy[index]["2"].num++;
          } else {
            this.$warning("Inventory shortage");
          }
        } else {
          this.$warning("Cannot exceed 4");
        }
      } else if (type === "senior") {
        if (this.ticketsCopy[index]["3"].num < 4) {
          if (
            this.ticketsCopy[index]["3"].num <
            this.ticketsCopy[index]["seniorStore"]
          ) {
            this.ticketsCopy[index]["3"].num++;
          } else {
            this.$warning("Inventory shortage");
          }
        } else {
          this.$warning("Cannot exceed 4");
        }
      } else if (type === "express") {
        // 同一张快速票总数不能超过4
        let currentNum =0
        this.expressTicket.forEach(ele=>{
          if(ele.id === this.expressTicket[index].id){
            currentNum += ele.num
          }       
      })
      if(currentNum>=4) return
        if (this.expressTicket[index].num < 4) {
          if (
            this.expressTicket[index].num <
            this.expressTicket[index].Currentinventory
          ) {
            this.expressTicket[index].num++;
          } else {
            this.$warning("Inventory shortage");
          }
        } else {
          this.$warning("Cannot exceed 4");
        }
      } else if (type === "option") {
        this.stockNum = 0;
        this.braceletList.forEach(item => {
          this.stockNum += item.num;
        });
        if (this.braceletList[index].num < 99) {
          if (this.stockNum < 10) {
            this.braceletList[index].num++;
          } else if (this.stockNum === 10) {
            this.$warning("Exceeds the maximum quantity");
          } else if (
            this.braceletList[index].num <
            this.braceletList[index].extrasTicketStore
          ) {
            this.$warning("Inventory shortage");
          }
        }
      } else if (type === "allYear") {
        console.log("all");
        if (this.ticketsCopy[index]["4"].num < 4) {
          if (
            this.ticketsCopy[index]["4"].num < // 下午
            this.ticketsCopy[index]["allYearStore"]
          ) {
            this.ticketsCopy[index]["4"].num++;
          } else {
            this.$warning("Inventory shortage");
          }
        } else {
          this.$warning("Cannot exceed 4");
        }
      }
      this.$forceUpdate();
      this.getSubtotal();
    },
    changeDate(date) {
      return formatDate(date);
    }
  }
};
</script>
<style lang="scss" scoped>
// flex布局
@mixin displyFlex() {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.container {
  width: 100%;

  .main {
    width: 1200px;
    margin: 0 auto;
    .aside {
      width: 240px;
      margin-left: 30px;
      #asideContent {
        position: relative;
        top: 0;
        .box-card {
          border-radius: 12px;
        }
      }
    }
  }
}
.card-body {
  padding: 15px;
}
.card-foot {
  padding: 15px 15px 20px;
}
.checkout-btn {
  width: 100%;
  margin-top: 24px;
  border-radius: 12px;
  background-color: #036ce2;
  font-size: 14px;
  font-weight: 400;
}
.aside .checkout-btn {
  font-weight: 700;
}
.checkout-btn_disable {
  box-shadow: none;
  color: #fff !important;
  background-color: #797a7d !important;
  border: none;
  cursor: pointer;
  opacity: 0.3;
  pointer-events: none;
  font-weight: 700;
}
.bigTitle {
  font-size: 24px;
  line-height: 1.25;
  font-weight: 900;
  padding-left: 10px;
  margin-top: 4px;
  margin-bottom: 19.92px;
}
.continue_font {
  display: -webkit-box;
  display: flex;
  margin-top: 24px;
  color: #036ce2;
  font-size: 12px;
  line-height: 1.25;
  font-weight: 700;
  letter-spacing: 1.5px;
  .el-icon-arrow-left {
    font-weight: 700;
    font-size: 25px;
    position: relative;
    top: -4px;
  }
}
.base {
  padding: 8px 16px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background: #e1f0ff;
  margin-bottom: 24px;
  font-weight: 500;
}
.deliveryBox {
  padding: 25px 10px;
  background-color: #e0f0ff;
  border-radius: 10px;
  h2 {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 700;
    color: #333;
    margin: 0 0 30px 10px;
  }
}
.content {
  padding: 0 12px 12px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: #0a0a0a;
}
.no_product_tip {
  color: #e61b23;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  padding: 0 24px 0 9px;
  margin: 47.92px 0 38px 0;
}
.delivery-method-item {
  @include displyFlex();
  padding: 8px 0 0 9px;
}
.checkBox {
  border: 1px solid #c5ced6;
  padding: 3px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  cursor: pointer;
  .blue-dot {
    background-color: #036ce2;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.checkBox_label {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
  color: #0a0a0a;
}
.ticket-item {
  padding: 0 25px;
  margin-bottom: 40px;
  &:not(:first-child) {
    padding-top: 24px;
    border-top: 1px solid #ddd;
  }
  .ticket_item_left {
    flex: 1.5;
    position: relative;
    .ticketName {
      font-size: 20px;
      line-height: 1.25;
      font-weight: 700;
      margin-bottom: 8px;
      @media (max-width: 640px) {
        padding-right: 55px;
      }
    }
    div {
      font-size: 14px;
      line-height: 1.5;
      font-weight: 400;
      color: #0a0a0a;
      .ticketDate {
        padding-top: 4px;
        font-size: 14px;
      }
    }
    .remove_font {
      color: #036ce2;
      font-size: 12px;
      font-weight: 700;
      cursor: pointer;
      margin-top: 8px;
    }
  }
  .ticket_item_right {
    @include displyFlex();
    justify-content: space-between;
    flex-direction: column;
    -webkit-box-orient: vertical;
    text-align: right;
    flex: 1;
    .ticket_item_right_inp-number-box {
      flex: 1;
      @include displyFlex();
      .ticketPrice {
        @include displyFlex();
        -webkit-box-orient: vertical;
        -ms-flex-direction: column;
        -webkit-flex-direction: column;
        flex-direction: column;
        width: 150px;
        span:first-child {
          font-size: 18px;
          font-weight: 400;
        }
      }
      /**票计数器start**/
      .input-number {
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-size: 24px;
        @include displyFlex();
        flex-wrap: nowrap;
        align-items: baseline;
        // flex: 1;
        .num {
          font-weight: 900;
          width: 40px;
          text-align: center;
          font-size: 24px;
        }
        .plus {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          border: 2px solid gainsboro;
          line-height: 15px;
          text-align: center;
          cursor: pointer;
        }
        .less {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          border: 2px solid gainsboro;
          line-height: 15px;
          text-align: center;
          cursor: pointer;
          position: relative;
        }
      }
      .subTotal {
        min-width: 100px;
        text-align: right;
        font-weight: 700;
        padding-left: 16px;
        flex: 1;
        white-space: nowrap;
      }
      /**票计数器end **/
    }
  }
}
.feed-detail {
  display: none;
}
.line-height30 {
  line-height: 30px;
}
.pl15 {
  padding-left: 15px;
}
.border-bottom {
  border-bottom: 1px solid gainsboro;
  padding-bottom: 15px;
}
.hiddenTicket {
  display: none;
}
.remove_font {
  cursor: pointer;
}
.order_box {
  display: none;
}
@media (max-width: 1200px) {
  .container {
    width: 100%;
    .main {
      box-sizing: border-box;
      width: 100%;
      padding: 0 10px 0 8px;
      box-sizing: border-box;
    }
  }
}
@media (max-width: 875px) {
  .hidden {
    display: none;
  }
  .order_box {
    display: block;
    .feed-detail {
      display: block;
      color: #333;
      font-weight: 700;
      line-height: 1.25;
      padding: 24px 15px;
      .order {
        font-size: 16px;
        line-height: 1.25;
        font-weight: 700;
        padding: 24px 0.9375rem 24px 0;
      }
      .total_box {
        border-bottom: 1px solid #dbdbdb;
        color: #12418b;
        padding-top: 24px;
        padding-bottom: 16px;
        font-size: 24px;
        line-height: 1.25;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        .txt_total {
          padding: 0 0.9375rem;
          flex: 1;
        }
        .totalPrice {
          flex: 1;
          padding: 0 0.9375rem;
          text-align: right;
        }
      }
    }
    .checkout-btn {
      background-color: #036ce2;
      color: #fff;
      border-radius: 12px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 10px;
      height: 45px;
      padding: 10px 32px;
      margin-top: 16px;
      margin-bottom: 10px;
    }
  }
  .checkout-btn_disable {
    margin-top: 0;
  }
  .order_box {
    .feed-detail {
      padding: 0;
      .total_box {
        border-bottom: none;
        font-size: 16px;
        line-height: 1.25;
        font-weight: 700;
        padding-top: 0;
        .txt_total {
          padding-left: 0;
        }
        .totalPrice {
          padding-right: 0;
        }
      }
    }
  }
  .ticket-item {
    .ticket_item_right {
      justify-content: space-between;
    }
  }
}
@media (max-width: 776px) {
  .small-weight {
    font-weight: 600;
  }
  .ticket-item {
    display: flex;
    flex-direction: column;
    padding: 0;
    .ticket_item_left {
      .remove_font {
        position: absolute;
        top: -3px;
        right: 0;
      }
    }
    .ticket_item_right {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      .ticket_item_right_inp-number-box {
        .ticketPrice {
          text-align: left;
        }
        .subTotal {
          max-width: none;
        }
      }
    }
  }
}
@media (max-width: 376px) {
  .ticket-item .ticket_item_left .ticketName {
    max-width: 310px;
  }
  .ticket-item
    .ticket_item_right
    .ticket_item_right_inp-number-box
    .ticketPrice {
    width: auto;
  }
}
/deep/ .el-card__header {
  text-align: center;
}
/deep/ .el-card__body {
  padding: 0;
}
/deep/ .card-foot {
  border-top: 1px solid gainsboro;
}
.font18 {
  font-size: 18px;
}
</style>
